import React from 'react'
import '../static/style/globalCss/common.scss'


const Loader = () => {
  return (
    <div className='loader-wrapper'>
      <div className="loader"></div>
    </div>
    
  )
}

export default Loader